import logo from './logo.svg';
import './App.css';
import {useEffect, useState} from "react";
import {TypeAnimation} from "react-type-animation";

function App() {
    const [currentText, setCurrentText] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);
    const [finalText, setFinalText] = useState('')
    const [finalIndex, setFinalIndex] = useState(0);
    const text = "Your Journey  "
    const text2 = " Companion"

    useEffect(() => {
        if (currentIndex < text.length) {
            const timeout = setTimeout(() => {
                setCurrentText(prevText => prevText + text[currentIndex]);
                setCurrentIndex(prevIndex => prevIndex + 1);
            }, 50);

            return () => clearTimeout(timeout);
        }
    }, [currentIndex, text, finalIndex, text2]);

    useEffect(() => {
        if (currentIndex >= text.length - 2) {
            if(finalIndex < text2.length) {
                const timeout = setTimeout(() => {
                    setFinalText(prevText => prevText + text2[finalIndex]);
                    setFinalIndex(prevIndex => prevIndex + 1);
                }, 50);
                return () => clearTimeout(timeout);
            }
        }
    }, [currentIndex, finalIndex, text2])

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
          <div style={{display: "flex", marginTop: "10px"}}>
          <div style={{fontSize: '1.2em', marginRight: "5px"}}>
            {text}
          </div>
          <span

              style={{ fontSize: '1.2em', color: "#FFC000"}}
          >
              {text2}
          </span>
          </div>
          <span style={{position: "absolute", top: '70%'}}>
          <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-end"}}>
             <div style={{marginTop: "100%"}}>
              Coming Soon...
              </div>
          </div>
              </span>
      </header>
    </div>
  );
}

export default App;
